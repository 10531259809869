import React from "react";


class Project extends React.Component {
    render() {
        var styleGithubButton = {
            display: 'inline-block',
            color: 'white'
        };
        var styleParagraph = {
            fontSize: '16px',
            color: '#ffd200'
        };
        var styleTitle = {
            fontSize: '14px',

        };
        var styleTable = {
            color: 'black',
            fontSize: '18px'
        };

        return (
            <div>
                <div className="project">
                    <table className="table" style={styleTable}>
                        <thead>
                        <tr>
                            <th width="15%">Name</th>
                            <th width="58%">Description</th>
                            <th width="15%">Language</th>
                            <th width="12%">Action</th>
                        </tr>
                        </thead>


                    </table>
                    <p style={styleParagraph}>More coming soon...</p>

                {/* <p style={styleParagraph}>All
                    source codes are available on my GitHub: &nbsp;
                <a href="https://github.com/nyu058"
                   className="btn azm-social azm-size-32 azm-r-square azm-github" style={styleGithubButton}><i
                    className="fa fa-github"></i></a></p> */}
            </div>
            </div>
        )
    };

}

export default Project