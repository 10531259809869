import React from 'react';
import './App.css';
import Home from './content/Home'
import Project from './content/Project'
import Contact from './content/Contact'

class Nav extends React.Component {

    render() {
        return (
            <nav className="navbar navbar-inverse">


                <ul className="nav navbar-nav">

                    <li className={(this.props.page === 'home' ? 'active' : '')}><div
                        onClick={() => this.props.setActiveTab('home')}>Home</div></li>
                    <li className={(this.props.page === 'project' ? 'active' : '')}><div
                        onClick={() => this.props.setActiveTab('project')}
                    >Projects</div></li>

                    <li className={(this.props.page === 'contact' ? 'active' : '')}><div
                        onClick={() => this.props.setActiveTab('contact')}>Contact</div></li>
                </ul>

            </nav>
        )
    }
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.renderPage = this.renderPage.bind(this)
    }

    renderPage(page){
        switch (page) {
            case 'home':
                return <Home />
            case 'project':
                return <Project />
            case 'contact':
                return <Contact />
        }
    }
    render() {

        return (
            <div>
                {this.renderPage(this.props.page)}
            </div>
        )
    };

}


class App extends React.Component {

    constructor(props) {
        var page = 'home';
        super(props);
        //TODO: save state on refresh
        this.state = {
            page: page
        };
        this.setActiveTab = this.setActiveTab.bind(this)

    }

    setActiveTab(currTab) {
        this.setState(
            {
                page: currTab
            }
        )
    }

    render() {
        var styleHeader = {
            fontSize: '32px',
            color: 'white'
        };
        return (
        <div className="App">
            <header className="background">
                <div className="container">

                    <div className="row">
                        <div className="col-md-10 col-sm-12 col-md-offset-1">
                            <div className="page-header" style={styleHeader}>Nathan Yu</div>
                            <Nav page={this.state.page} setActiveTab={this.setActiveTab}/>
                            <Content page={this.state.page}/>

                        </div>
                    </div>
                </div>
            </header>
        </div>
    )

    }
}

export default App;
