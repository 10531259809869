import React from "react";


class Home extends React.Component {
    render() {

        var styleParagraph = {
            fontSize: '20px',
            color: '#ffd200'
        };
        return (
            <div>
                {/* <h3 align="center" style={styleParagraph}>nathan5508@gmail.com</h3>
                <br />
                <div align="center">
                    <a href="https://github.com/nyu058"
                        className="btn azm-social azm-size-64 azm-r-square azm-github"
                        title="Github"><i
                            className="fa fa-github"></i></a>
                    <a href="https://www.linkedin.com/in/hanyangyu/"
                        className="btn azm-social azm-size-64 azm-r-square azm-linkedin" title="Linkedin"><i
                            className="fa fa-linkedin"></i></a>
                </div> */}
            </div>
        )
    };

}

export default Home