import React from "react";


class Home extends React.Component {
    render() {
        var styleImg = {
            float: 'right',
            padding: '10px'
        };
        var styleHeading = {
            fontSize: 'x-large',
            color: '#ffd200'
        };
        var styleParagraph = {
            fontSize: '20px',
            color: '#ffd200'
        };
        return (
            <div>
                
                <p align="center">
                    <span style={styleHeading}>Under Construction</span>
                </p>
                <img src="0.jpg" style={styleImg} alt='' width="400px" height="320px"/>

                {/* <p style={styleParagraph}>Under Construction</p> */}
                {/* <div align="center">
                    <a href="https://github.com/nyu058"
                        className="btn azm-social azm-size-64 azm-r-square azm-github"
                        title="Github"><i
                            className="fa fa-github"></i></a>
                    <a href="https://www.linkedin.com/in/hanyangyu/"
                        className="btn azm-social azm-size-64 azm-r-square azm-linkedin" title="Linkedin"><i
                            className="fa fa-linkedin"></i></a>
                </div> */}
            </div>
        )
    };

}

export default Home